<template>
    <div>       
         <!-- MODAL CREATE ROLE-->
        <b-modal
        id="modal-create-role"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        v-model="openRoleModel"
        title="Create Employee Role"
        @cancel='$root.$emit("stopSpinner")'
        @ok="saveUserRole($event)"   
        :ok-disabled='modalSpinner' 
        :cancel-disabled='modalSpinner'    
        >        
         <validation-observer ref="createuserRoleValid">    
            <form ref="form"  @submit.stop.prevent="handleSubmit">  
                <b-spinner variant="primary" v-if="modalSpinner" large class="pageLoadSpinners"/>                      
                <b-row>
                    <b-col @mousedown="outsideDropdown()">
                        <b-form-group label="Employee Role Name" label-for="roleName" >
                            <validation-provider
                                #default="{ errors }"
                                name="role name"                                
                                rules="required"
                            >
                                <b-form-input
                                id="userRole"
                                name='role'
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"   
                                v-model="userRole.name"     
                                :disabled='isSpinner'        
                                >               
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Select Employee" label-for="empName">
                            <!-- <v-select
                            v-model="selectedEmp"
                            :options='empArray'
                            id="empName"
                            :reduce="name => name.id" label="name" 
                            placeholder="select"
                            />                -->
                            <!-- <div class="course_wrapper" @mouseleave="eventfordropdown()"> -->
                            <div class="course_wrapper">
                                <div class="dropdown" @click="showDropdown">
                                <div class="overselect"></div>
                                <validation-provider #default="{ errors }" name="user">
                                <select class="c-form-input form-control" :disabled='isSpinner' aria-placeholder="Select" v-model="userRole.selectedUser" :state="errors.length > 0 ? false:null">
                                    <option value="" selected disabled>Select</option>                                 
                                </select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </div>
                                <div class="multiselect multiselect_employee" v-if="show">
                                <ul>
                                    <li class="mb-1">
                                        <input type="text" class="form-control" placeholder="Search..." v-model="searchValue"/>
                                    </li>
                                <!-- </ul>    
                                <ul> -->                                     
                                    <li v-for="(option, index) in filteredUsers" :key="index" class="">
                                    <input type="checkbox" :disabled='isSpinner' :id="index" :value="option.id" v-model="userRole.selectedUser">
                                    <label :for="index">{{ option.name }}</label>
                                    </li>   
                                    <li v-show="filteredUsers.length == 0">
                                        <small class="text-danger"> employee data not exist</small>
                                    </li>                                 
                                </ul>
                                </div>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row> 
                <div @mousedown="outsideDropdown()">
                <b-form-group>
                    <label @mousedown="outsideDropdown()">Assigned Modules</label>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="userRole.StudentInquiry.isSelected"
                                name="checkbox-1"
                                value="Student Inquiry"
                                @change="StudentInquiryChange()"
                                :disabled='isSpinner'
                            >
                            Student Inquiry
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="Student Inquiry"                                
                                rules="required"                                
                             >
                                <b-form-radio-group
                                class=""
                                name='studentInq'
                                :options="['Read', 'Write']"   
                                v-model="userRole.StudentInquiry.allow" 
                                :state="errors.length > 0 ? false:null"
                                :disabled='userRole.StudentInquiry.isSelected.length == 0'                           
                                >
                                </b-form-radio-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-2"
                                v-model="userRole.StudentMgmt.isSelected"
                                name="checkbox-1"
                                value="Student List"
                                checked="Student List"
                                @change="StudentMgmtChange()"
                                :disabled='isSpinner'
                            >
                            Student List
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="Student Managment"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            name='studentMgmt'
                            :options="['Read', 'Write']"  
                            v-model="userRole.StudentMgmt.allow" 
                            :state="errors.length > 0 ? false:null"
                            :disabled='userRole.StudentMgmt.isSelected.length == 0'                                 
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-3"
                                v-model="userRole.AdminUserMgmt.isSelected"
                                name="checkbox-1"
                                value="Admin User"
                                @change="AdminUserMgmtChange()"
                                :disabled='isSpinner'
                            >
                            Admin User
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="Admin User Managment"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            name='adminUserMgmt'
                            :options="['Read', 'Write']"    
                            v-model="userRole.AdminUserMgmt.allow"
                            :state="errors.length > 0 ? false:null" 
                            :disabled='userRole.AdminUserMgmt.isSelected.length == 0'                              
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1" >
                            <b-form-checkbox
                                id="checkbox-4"
                                v-model="userRole.UniversityCourseMgmt.isSelected"
                                name="checkbox-1"
                                value="University/Courses"
                                @change="UniversityCourseMgmtChange()"
                                :disabled='isSpinner'
                            >
                            University/Courses
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="Course Managment"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            name='courceMgmt'
                            :options="['Read', 'Write']"   
                            v-model="userRole.UniversityCourseMgmt.allow" 
                            :state="errors.length > 0 ? false:null"
                            :disabled='userRole.UniversityCourseMgmt.isSelected.length == 0'                           
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-5"
                                v-model="userRole.docModuleMgmt.isSelected"
                                name="checkbox-1"
                                value="Documents"
                                @change="docModuleMgmtChange()"
                                :disabled='isSpinner'
                            >
                            Documents
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1" >
                            <validation-provider
                                #default="{ errors }"
                                name="Doc. Category Managment"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']" 
                            name='docModule'    
                            v-model="userRole.docModuleMgmt.allow"  
                            :state="errors.length > 0 ? false:null"
                            :disabled='userRole.docModuleMgmt.isSelected.length == 0'                         
                            >
                            </b-form-radio-group>
                             <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-6"
                                v-model="userRole.companyDoc.isSelected"
                                name="checkbox-1"
                                value="Company Document" 
                                @change="companyDocChange()" 
                                :disabled='isSpinner'                              
                            >
                            Company Document
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="company document"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.companyDoc.allow"
                            name='companydoc'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.companyDoc.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-7"
                                v-model="userRole.promotional.isSelected"
                                name="checkbox-1"
                                value="Promotional Notification" 
                                @change="promotionalChange()" 
                                :disabled='isSpinner'                              
                            >
                            Promotional notification
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="promotional notification"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.promotional.allow"
                            name='promotional'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.promotional.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-8"
                                v-model="userRole.announcement.isSelected"
                                name="checkbox-1"
                                value="Announcement" 
                                @change="announcementChange()" 
                                :disabled='isSpinner'                              
                            >
                            Announcement
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="announcement"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.announcement.allow"
                            name='announcement'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.announcement.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-9"
                                v-model="userRole.community.isSelected"
                                name="checkbox-1"
                                value="Community" 
                                @change="communityChange()" 
                                :disabled='isSpinner'                              
                            >
                            Community
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="community"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.community.allow"
                            name='community'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.community.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-10"
                                v-model="userRole.infoDoc.isSelected"
                                name="checkbox-1"
                                value="Information Document" 
                                @change="infoDocChange()" 
                                :disabled='isSpinner'                              
                            >
                            Information Document
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="infoDoc"                                
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.infoDoc.allow"
                            name='infoDoc'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.infoDoc.isSelected.length == 0'                                                                   
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-12"
                                v-model="userRole.eduMent.isSelected"
                                name="checkbox-1"
                                value="Education Fields Management" 
                                @change="fiedlMgntChange()" 
                                :disabled='isSpinner'                              
                            >
                            Education Fields Management
                        </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="eduMent"                              
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.eduMent.allow"
                            name='eduMent'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.eduMent.isSelected.length == 0'                                                                                                                              
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-11"
                                v-model="userRole.noticeDoc.isSelected"
                                name="checkbox-1"
                                value="Notice Management" 
                                @change="noticeMangChange()" 
                                :disabled='isSpinner'                              
                            >
                            Notice Management
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="noticeDoc"                              
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.noticeDoc.allow"
                            name='noticeDoc'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.noticeDoc.isSelected.length == 0'                                                                                                                              
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <b-form-checkbox
                                id="checkbox-13"
                                v-model="userRole.noteDoc.isSelected"
                                name="checkbox-1"
                                value="Application Note Management" 
                                @change="noteMangChange()" 
                                :disabled='isSpinner'                              
                            >
                            Application Note Management
                            </b-form-checkbox>
                        </b-col>
                        <b-col md="6" sm="2" class="pb-1 pt-1">
                            <validation-provider
                                #default="{ errors }"
                                name="noteDoc"                              
                                rules="required"                                
                             >
                            <b-form-radio-group
                            class=""
                            :options="['Read', 'Write']"  
                            v-model="userRole.noteDoc.allow"
                            name='noteDoc'
                            :state="errors.length > 0 ? false:null"   
                            :disabled='userRole.noteDoc.isSelected.length == 0'                                                                                                                              
                            >
                            </b-form-radio-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                </b-form-group> 
                </div>
            </form>
       </validation-observer>
        </b-modal>

        <b-card>
            <b-row class="mb-2">
                <b-col md="2" class="">
                    <b-form-input v-model="filter" placeholder="Search..."/>
                </b-col>
               <b-col
                md="10"
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"       
                >
                <v-select
                    v-model="perPage"
                    style="width: 90px"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table
                        striped
                        hover
                        responsive
                        show-empty
                        empty-text="No matching records found"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="userRoleList"
                        :fields="userRoleColumn"
                        :filter="filter"
                        v-model="roleListTable"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        class="position-relative role_mgmt_table"
                    >
                    <template #cell(assignUser)="data">
                        <span>
                            {{data.item.assignUser.length}}
                        </span>     
                    </template>
                    <template #cell(assignModules)="data">
                        <span v-for='(assign,index) in data.item.assignModules' :key="index">
                            <span>{{assign.name}}</span>
                            <span v-if='index !== Number(data.item.assignModules.length - 1)'>,</span>
                        </span>     
                    </template>                    
                    <template #cell(id)="data">
                        <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                            <b-img src='@/assets/images/erflog/Edit.png' @click="handleRowClick(data.item)" class="action_icon"/>
                            <b-img src='@/assets/images/erflog/Delete.png' @click.prevent="selectedRemoveData=data" v-b-modal.delete-field class="action_icon"/> 
                        </div>
                        <div v-else>
                            --
                        </div>
                    </template>
                    </b-table>
                </b-col>
            </b-row>  
            <div class="mx-2 mb-2">
                    <b-row>
                        <!-- PAGINATION TEXT -->
                        <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                        </b-col>
                        
                        <!-- PAGINATION FOOTER -->
                        <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-end"
                        >
                            <b-pagination
                                  v-model="currentPage"
                                  :total-rows="paginationCount"
                                  :per-page="perPage"
                                  align="right"
                                  first-number
                                  last-number
                                  prev-class="prev-item"
                                  next-class="next-item"
                                  class="mt-1 mb-0 custom_pagination"
                              >
                              <template #prev-text>
                                <feather-icon
                                  icon="ChevronLeftIcon"
                                  size="18"
                                />
                            </template>
                            <template #next-text>
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                              />
                            </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
              </div>           
        </b-card>

         <!-- DELETE MODEL -->
        <b-modal
        id="delete-field"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedRemoveData=''"
        v-if="selectedRemoveData!=''"
        @cancel='isSpinner=false'
        @ok="deleteField(selectedRemoveData)"
      >
     <b-spinner variant="primary" v-if="modalSpinner" large class="pageLoadSpinners"/>                      
      <div class="create_account_img">
      <img src="@/assets/images/erflog/DeletePopup.png" alt="">
      </div>
      <div style="text-align: center;" v-if="selectedRemoveData!=''">
        <label class="s_modal_title">Delete Field</label>
        <p>Are you sure you want to delete these document category?</p>
      </div>
       </b-modal>

       <!-- <div v-if='isSpinner' class="loading"></div> -->
       <div v-if="isSpinner" class="loading loading-custom">         
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>     
        </div>
    </div>
</template>
<script>
import {
  BPagination,BSpinner, BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BRow, BCol, BFormCheckbox, BFormRadioGroup, BCard, BTable, BImg, BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'
//FIREBASE IMPORT
import firebase from "firebase"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dbCollections } from '@/utils/firebaseCollection.js';
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import * as inserQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js';
extend('required',required)
export default{
   components: {
    BPagination,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BFormCheckbox,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BImg,
    BTable,
    BFormSelect,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  }, 
   data(){
       return{
           openRoleModel:false,
           selectedEmp:'',  
           allUser:[],
           empArray:[],           
           show:false,
           searchValue:'',
           userRole:{
               name:'',
               selectedUser:[],
               assignModules:[],
               StudentInquiry:{
                   isSelected:[],
                   allow:true
               },
               StudentMgmt:{
                   isSelected:[],
                   allow:false
               },
               AdminUserMgmt:{
                   isSelected:[],
                   allow:false
               },
               UniversityCourseMgmt:{
                   isSelected:[],
                   allow:false
               },
               docModuleMgmt:{
                   isSelected:[],
                   allow:false
               },
               companyDoc:{
                   isSelected:[],
                   allow:false
               },
                promotional:{
                   isSelected:[],
                   allow:false
               },
                announcement:{
                   isSelected:[],
                   allow:false
               },
                community:{
                   isSelected:[],
                   allow:false
               },     
               infoDoc:{
                   isSelected:[],
                   allow:false
               },       
               eduMent:{
                    isSelected:[],
                   allow:false
               },
               noticeDoc:{
                   isSelected:[],
                   allow:false
               }  ,     
               noteDoc:{
                   isSelected:[],
                   allow:false
               }       
           },
           assignModules:[],
           userRoleList:[],
           userRoleColumn:[
               {key:'userRoleName',label:'Employee Role Name',sortable: true},
               {key:'assignUser',label:'No. of Employee',sortable: true},
               {key:'assignModules',label:'Assigned Modules',sortable: false},
               {key:'id',label:'Action'}
           ],
           totalRows: 1,
           currentPage: 1,
           filter: null,
           filterOn: [],
           perPage: 10,
            pageOptions: [10, 25, 50, 100],
           selectedRemoveData:null,
           isSpinner:false,
           assignuserRoleId:[],
           modalSpinner:false,
           roleListTable:[],
           paginationCount:0
       }
   },
   watch: {
        $route: function () {
            this.getData();          
        }
   },
   created(){
        this.$root.$on('openCreateRolePopUp',()=>{        
           this.openRoleModel = true;
           this.userRole = {
               name:'',
               selectedUser:[],
               assignModules:[],
               StudentInquiry:{
                   isSelected:[],
                   allow:false
               },
               StudentMgmt:{
                   isSelected:[],
                   allow:false
               },
               AdminUserMgmt:{
                   isSelected:[],
                   allow:false
               },
               UniversityCourseMgmt:{
                   isSelected:[],
                   allow:false
               },
               docModuleMgmt:{
                   isSelected:[],
                   allow:false
               },
               companyDoc:{
                   isSelected:[],
                   allow:false
               },   
                promotional:{
                   isSelected:[],
                   allow:false
               },
                announcement:{
                   isSelected:[],
                   allow:false
               },
                community:{
                   isSelected:[],
                   allow:false
               },
               infoDoc:{
                   isSelected:[],
                   allow:false
               },
               eduMent:{
                    isSelected:[],
                   allow:false
               },
               noticeDoc:{
                   isSelected:[],
                   allow:false
               },
               noteDoc:{
                   isSelected:[],
                   allow:false
               }
           };
           this.show = false         
           this.$root.$emit('stopSpinner')
       })     
       this.getData();
   },
   methods:{
       outsideDropdown(){
           this.eventfordropdown()
        //    alert("outside")
       },
       getData(){
           var self = this;
        //    setTimeout(()=>{
               getQuery.getDataWithSpecificWhereCondition(dbCollections.USERROLES,'isDeleted','==',false,res=>{ 
                this.userRoleList = res;
                this.paginationCount = this.userRoleList.length
                if(res.length){
                    res.forEach(element=>{
                        if(element.isDeleted == false){
                        if(element.assignUser.length > 0){                            
                            Array.prototype.push.apply(this.assignuserRoleId,element.assignUser);
                        }
                        }
                    })
                }
               }) 
        //    },1000)
           setTimeout(()=>{
           getQuery.getDataWithSpecificWhereCondition(dbCollections.USERS,'isDeleted','==',false,res=>{
               self.allUser = [];
               self.empArray = [];
               if(res){  
                   res.forEach(ele=>{                      
                       if(ele.role.toLowerCase() == 'employee'){
                           if(this.assignuserRoleId.length > 0){
                               let searchIndex = this.assignuserRoleId.findIndex(selectedVal=>{
                                    return selectedVal == ele.id
                               })
                               if(searchIndex <= -1){
                                    self.allUser.push(ele)
                               }
                           }
                           else{
                            //    self.allUser.push(ele)
                                if(self.allUser.length > 0){
                                    let removeDup = this.allUser.findIndex(selectedVal=>{
                                        return selectedVal.id == ele.id
                                    })
                                    if(removeDup <= -1){
                                        self.allUser.push(ele)
                                    }
                                }else{    
                                self.allUser.push(ele)
                                }
                           }                       
                       }
                   })                    
                  if(self.allUser.length){
                   self.allUser.forEach(element => {
                       if(element){
                           self.empArray.push({'name':element.name,'id':element.id})   
                       }
                   });                   
                   }
               }
           }) 
           },2000)
        },
       showDropdown() {
        this.show = !this.show
       },
       saveUserRole(event){
        // Prevent modal from closing
        event.preventDefault()
        // Trigger submit handler
        this.handleSubmit()        
       },
       resetFields(){
           this.assignModules = []
       },
       updateEmployeeRole(data,roleName,roleId,cb){
        // var self = this;   
        let updateField = {
            'employeeRole':{
                'id':roleId,
                'name':roleName
            },
            'updatedAt':firebase.firestore.FieldValue.serverTimestamp()
        }
        updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,data,updateField,updateRes=>{
            cb(updateRes)
        })
       },
       handleSubmit(){
        var self = this;   
        // Exit when the form isn't validEditFormValidator
        this.$refs.createuserRoleValid.validate().then(success=>{
                if(success){
                    this.isSpinner = true;
                    this.modalSpinner = true;
                    let roleModules = [];                   
                    if(this.userRole.StudentInquiry.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.StudentInquiry.isSelected[0],
                            allow:this.userRole.StudentInquiry.allow
                        }) 
                    }
                    if(this.userRole.StudentMgmt.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.StudentMgmt.isSelected[0],
                            allow:this.userRole.StudentMgmt.allow
                        }) 
                    }
                    if(this.userRole.AdminUserMgmt.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.AdminUserMgmt.isSelected[0],
                            allow:this.userRole.AdminUserMgmt.allow
                        }) 
                    }
                    if(this.userRole.UniversityCourseMgmt.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.UniversityCourseMgmt.isSelected[0],
                            allow:this.userRole.UniversityCourseMgmt.allow
                        }) 
                    }
                    if(this.userRole.docModuleMgmt.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.docModuleMgmt.isSelected[0],
                            allow:this.userRole.docModuleMgmt.allow
                        }) 
                    }
                    if(this.userRole.companyDoc.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.companyDoc.isSelected[0],
                            allow:this.userRole.companyDoc.allow
                        }) 
                    } 
                    if(this.userRole.promotional.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.promotional.isSelected[0],
                            allow:this.userRole.promotional.allow
                        }) 
                    }
                    if(this.userRole.announcement.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.announcement.isSelected[0],
                            allow:this.userRole.announcement.allow
                        }) 
                    } 
                    if(this.userRole.community.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.community.isSelected[0],
                            allow:this.userRole.community.allow
                        }) 
                    } 
                    if(this.userRole.infoDoc.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.infoDoc.isSelected[0],
                            allow:this.userRole.infoDoc.allow
                        }) 
                    } 
                    if(this.userRole.eduMent.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.eduMent.isSelected[0],
                            allow:this.userRole.eduMent.allow
                        })
                    }
                    if(this.userRole.noticeDoc.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.noticeDoc.isSelected[0],
                            allow:this.userRole.noticeDoc.allow
                        }) 
                    } 
                    if(this.userRole.noteDoc.isSelected.length > 0){
                        roleModules.push({
                            name:this.userRole.noteDoc.isSelected[0],
                            allow:this.userRole.noteDoc.allow
                        }) 
                    } 
                    let saveObj = {
                        'userRoleName':this.userRole.name,
                        'createdAt' : firebase.firestore.FieldValue.serverTimestamp(),
                        'updatedAt' : firebase.firestore.FieldValue.serverTimestamp(),
                        'isDeleted': false,
                        'assignUser':this.userRole.selectedUser,
                        'assignModules':roleModules,                        
                    } 
                    inserQuery.addDataToRootCollection(dbCollections.USERROLES,saveObj,res=>{
                        if(res.id){
                            let updateField = {
                                'id':res.id
                            }
                            this.isSpinner = false                            
                                updateQuery.updateRootCollectionDataByDocId(dbCollections.USERROLES,res.id,updateField,updateRes=>{
                                    let count = 0;
                                    let fileArry = this.userRole.selectedUser;
                                    let userRole = this.userRole.name;
                                    if(this.userRole.selectedUser !== null && this.userRole.selectedUser.length > 0){
                                    const uploadFileFunction = (row) => {
                                    if(count >= fileArry.length){    
                                        this.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: 'Employee Role Added Successfully',
                                                icon: 'CoffeeIcon',
                                                variant: 'success',                               
                                            },
                                        })
                                        this.openRoleModel = false;
                                        this.isSpinner = false
                                        this.modalSpinner = false;
                                        this.getData();    
                                        return           
                                    }else{
                                        self.updateEmployeeRole(row,userRole,res.id,(saveRes)=>{
                                            count += 1;
                                            uploadFileFunction(fileArry[count]);
                                        });      
                                    }        
                                    }
                                    uploadFileFunction(fileArry[count]);
                                    }else{
                                      this.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: 'Employee Role Added Successfully',
                                                icon: 'CoffeeIcon',
                                                variant: 'success',                               
                                            },
                                      })
                                    this.openRoleModel = false;
                                    this.isSpinner = false
                                    this.modalSpinner = false;
                                    this.getData();   
                                    } 
                               })
                        this.isSpinner = false
                        }
                     })
                }
        })
       },
       StudentInquiryChange(){
           if(this.userRole.StudentInquiry.isSelected.length !== 0){
           this.userRole.StudentInquiry.allow = 'Read';
           this.$refs.createuserRoleValid.reset()
               if(this.userRole.StudentInquiry.isSelected.length > 0){
                   this.userRole.StudentInquiry.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
           this.userRole.StudentInquiry.allow = false;
            if(this.userRole.StudentInquiry.isSelected.length > 0){
                    this.userRole.StudentInquiry.isSelected.forEach(val=>{
                        this.assignModules.push(val)
                    })
            }
           }
       },
       StudentMgmtChange(){
            // this.userRole.StudentMgmt.isSelected = checked ? userRole.StudentMgmt.isSelected.slice() : []
           if(this.userRole.StudentMgmt.isSelected.length !== 0){
               this.userRole.StudentMgmt.allow = 'Read';
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.StudentMgmt.isSelected.length > 0){
                   this.userRole.StudentMgmt.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
           this.userRole.StudentMgmt.allow = false;
           this.$refs.createuserRoleValid.reset()
               if(this.userRole.StudentMgmt.isSelected.length > 0){
                   this.userRole.StudentMgmt.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       AdminUserMgmtChange(){
           if(this.userRole.AdminUserMgmt.isSelected.length == 0){
               this.userRole.AdminUserMgmt.allow = false;
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.AdminUserMgmt.isSelected.length > 0){
                   this.userRole.AdminUserMgmt.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.AdminUserMgmt.allow = 'Read';
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.AdminUserMgmt.isSelected.length > 0){
                   this.userRole.AdminUserMgmt.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       UniversityCourseMgmtChange(){
        if(this.userRole.UniversityCourseMgmt.isSelected.length == 0){
               this.userRole.UniversityCourseMgmt.allow = false
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.UniversityCourseMgmt.isSelected.length > 0){
                   this.userRole.UniversityCourseMgmt.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.UniversityCourseMgmt.allow = 'Read'
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.UniversityCourseMgmt.isSelected.length > 0){
                   this.userRole.UniversityCourseMgmt.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }   
       },
       docModuleMgmtChange(){
          if(this.userRole.docModuleMgmt.isSelected.length == 0){
               this.userRole.docModuleMgmt.allow = false
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.docModuleMgmt.isSelected.length > 0){
                   this.userRole.docModuleMgmt.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.docModuleMgmt.allow = 'Read';
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.docModuleMgmt.isSelected.length > 0){
                   this.userRole.docModuleMgmt.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }    
       },
       companyDocChange(){           
           if(this.userRole.companyDoc.isSelected.length == 0){
               this.userRole.companyDoc.allow = false
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.companyDoc.isSelected.length > 0){
                   this.userRole.companyDoc.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.companyDoc.allow = 'Read'
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.companyDoc.isSelected.length > 0){
                   this.userRole.companyDoc.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       promotionalChange(){           
           if(this.userRole.promotional.isSelected.length == 0){
               this.userRole.promotional.allow = false
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.promotional.isSelected.length > 0){
                   this.userRole.promotional.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.promotional.allow = 'Read'
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.promotional.isSelected.length > 0){
                   this.userRole.promotional.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
        announcementChange(){           
           if(this.userRole.announcement.isSelected.length == 0){
               this.userRole.announcement.allow = false
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.announcement.isSelected.length > 0){
                   this.userRole.announcement.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.announcement.allow = 'Read'
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.announcement.isSelected.length > 0){
                   this.userRole.announcement.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
        communityChange(){           
           if(this.userRole.community.isSelected.length == 0){
               this.userRole.community.allow = false
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.community.isSelected.length > 0){
                   this.userRole.community.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.community.allow = 'Read'
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.community.isSelected.length > 0){
                   this.userRole.community.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       infoDocChange(){           
           if(this.userRole.infoDoc.isSelected.length == 0){
               this.userRole.infoDoc.allow = false
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.infoDoc.isSelected.length > 0){
                   this.userRole.infoDoc.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.infoDoc.allow = 'Read'
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.infoDoc.isSelected.length > 0){
                   this.userRole.infoDoc.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       fiedlMgntChange(){           
           if(this.userRole.eduMent.isSelected.length == 0){
               this.userRole.eduMent.allow = false
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.eduMent.isSelected.length > 0){
                   this.userRole.eduMent.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.eduMent.allow = 'Read'
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.eduMent.isSelected.length > 0){
                   this.userRole.eduMent.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       noticeMangChange(){           
           if(this.userRole.noticeDoc.isSelected.length == 0){
               this.userRole.noticeDoc.allow = false
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.noticeDoc.isSelected.length > 0){
                   this.userRole.noticeDoc.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.noticeDoc.allow = 'Read'
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.noticeDoc.isSelected.length > 0){
                   this.userRole.noticeDoc.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       noteMangChange(){           
           if(this.userRole.noteDoc.isSelected.length == 0){
               this.userRole.noteDoc.allow = false
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.noteDoc.isSelected.length > 0){
                   this.userRole.noteDoc.isSelected.forEach(val=>{
                       let findInd = this.assignModules.findIndex(ele=>{
                           return ele==val
                       })
                       if(findInd > -1){
                           this.assignModules.splice(findInd,1)
                       }                       
                   })
               }
           }else{
               this.userRole.noteDoc.allow = 'Read'
               this.$refs.createuserRoleValid.reset()
               if(this.userRole.noteDoc.isSelected.length > 0){
                   this.userRole.noteDoc.isSelected.forEach(val=>{
                       this.assignModules.push(val)
                   })
               }
           }
       },
       onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
        if(this.filter !== ""){
            this.paginationCount = this.roleListTable.length;
        }else{
            this.paginationCount = this.userRoleList.length;
        }
       },
       eventfordropdown(){
           this.show = false
        //    this.showDropdown();
       },
       handleRowClick(data){
            this.$router.push({name:'role-detail-mgmt',params:{id:data.id,name:data.userRoleName}})
       },
       deleteField(data){  
        this.isSpinner = true; 
        if(data.item.assignUser.length <= 0){
        this.modalSpinner = true; 
          let obj = {
                'isDeleted':true
          }
          updateQuery.updateRootCollectionDataByDocId(dbCollections.USERROLES,data.item.id,obj,res=>{
                this.isSpinner = false;
                this.modalSpinner = false; 
                 this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Document Deleted Successfully',
                            icon: 'CoffeeIcon',
                            variant: 'success',                               
                        },
                }) 
          })
        }else{
            this.isSpinner = false;
            this.modalSpinner = false; 
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Warning',
                        icon: 'CoffeeIcon',
                        text:'You can not delete role in which users are assigns.',
                        variant: 'danger',                               
                    },
            }) 
        }
    
    },
   },
   computed: {
     filteredUsers() {
       var self = this;
       var tempUsers = self.allUser
         // SEARCH BY NAME, DESIGNATION & EMPLOYEEID	
       if (self.searchValue != '' && self.searchValue) {	
             tempUsers = tempUsers.filter((item) => {	
              return item.name.toLowerCase().indexOf(self.searchValue.toLowerCase()) >= 0	         
             })	
         }	
       return tempUsers	
     },
    dataMeta(){
        const localItemsCount = this.roleListTable ? this.roleListTable.length : 0;
        if(this.filter !== "" && this.filter !== null){
          return {
            from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
            to: this.perPage * (this.currentPage - 1) + localItemsCount,
            of: this.roleListTable.length,
          }
        }else{
            return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.userRoleList.length,
            }
        }
    }
  }
}
</script>
<style scoped>
.overselect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.dropdown { 
  position: relative; 
  cursor: pointer;
}
.multiselect {
  position: relative;
}
.multiselect ul{
    border: 1px solid #ddd;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    left: 0px;
    padding: 8px 8px;
    position: absolute;
    top: 0rem;
    width: 100%;
    background-color: white;
    list-style: none;
    max-height: 150px;
    overflow: auto;
    z-index: 99;
}
</style>